/* eslint-disable react/prop-types */
import "./index.scss";

import React from "react";
import FontAwesome from "react-fontawesome";

const Socials = () => (
  <div className="socials">
    <FontAwesome
      className="socials-item"
      name="twitter"
      onClick={() => window.open("https://twitter.com/fmfa_official", "_blank")}
    />
    <FontAwesome
      className="socials-item"
      name="facebook"
      onClick={() =>
        window.open("https://web.facebook.com/fezilemdletshefashion", "_blank")
      }
    />
    <FontAwesome
      className="socials-item"
      name="linkedin"
      onClick={() =>
        window.open(
          "https://za.linkedin.com/in/fezile-l-mdletshe-9b66405b",
          "_blank"
        )
      }
    />
    <FontAwesome
      className="socials-item"
      name="instagram"
      onClick={() =>
        window.open(
          "https://www.instagram.com/fezile_mdletshe_fashion_agency/",
          "_blank"
        )
      }
    />
    <FontAwesome
      className="socials-item"
      name="whatsapp"
      onClick={() => window.open("https://wa.me/27678597724", "_blank")}
    />
  </div>
);

export default Socials;
