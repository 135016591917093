import "./index.scss";

import React from "react";

import NavBarAndFooter from "components/NavBarAndFooter";
import ProgramCard from "./ProgramCard";
import programs from "./ProgramsData";

const Programs = (props) => (
  <NavBarAndFooter props={props}>
    <div className="programs-page animate__animated animate__fadeIn">
      <div className="top-section">
        <div className="title">Programs</div>
        <div className="sub-title">FMFA {"  >  "} Programs</div>
      </div>
      {programs.map((program, index) => (
        <ProgramCard 
          key={index}           
          image={program.image}
          title={program.title} 
          body={program.body} 
        />
      ))}
    </div>
  </NavBarAndFooter>
);

export default Programs;
