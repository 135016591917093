/* eslint-disable react/prop-types */
import "./bottom-bar.scss";

import React from "react";
import FontAwesome from "react-fontawesome";

const BottomBar = ({
  scrollY,
  props,
  contactVisible,
  sidebarVisible,
  setSideBarHidden,
  setContactVisible,
  setSideBarVisible,
}) => {
  const toggleSideBar = () => {
    if (sidebarVisible) {
      setSideBarVisible(false);
      setSideBarHidden(true);
    } else {
      setSideBarVisible(true);
      setSideBarHidden(false);
    }
  };

  console.log("scrollY", scrollY);
  return (
    <div className={`bottom-bar ${scrollY > 33 && "position-fixed-top"}`}>
      <div className="bottom-bar-left">
        <FontAwesome
          className="bottom-bar-left-item"
          name="twitter"
          onClick={() =>
            window.open("https://twitter.com/fmfa_official", "_blank")
          }
        />
        <FontAwesome
          className="bottom-bar-left-item"
          name="facebook"
          onClick={() =>
            window.open(
              "https://web.facebook.com/fezilemdletshefashion",
              "_blank"
            )
          }
        />
        <FontAwesome
          className="bottom-bar-left-item"
          name="linkedin"
          onClick={() =>
            window.open(
              "https://za.linkedin.com/in/fezile-l-mdletshe-9b66405b",
              "_blank"
            )
          }
        />
        <FontAwesome
          className="bottom-bar-left-item"
          name="instagram"
          onClick={() =>
            window.open(
              "https://www.instagram.com/fezile_mdletshe_fashion_agency/",
              "_blank"
            )
          }
        />
        <FontAwesome
          className="bottom-bar-left-item"
          name="whatsapp"
          onClick={() => window.open("https://wa.me/27678597724", "_blank")}
        />
      </div>
      <div className="bottom-bar-center">
        <span
          className="bottom-bar-center-item"
          onClick={() => props.history.push("/about")}
        >
          ABOUT
        </span>
        <span
          className="bottom-bar-center-item"
          onClick={() => props.history.push("/programs")}
        >
          PROGRAMS
        </span>
        <span
          className="bottom-bar-center-item"
          onClick={() => props.history.push("/projects")}
        >
          PROJECTS
        </span>
        <div
          className="bottom-bar-center-item logo"
          onClick={() => props.history.push("/")}
        />
        <span
          className="bottom-bar-center-item"
          onClick={() => props.history.push("/office")}
        >
          OFFICE
        </span>
        <span
          className="bottom-bar-center-item"
          onClick={() => props.history.push("/principles")}
        >
          PRINCIPLES
        </span>
        <span
          className="bottom-bar-center-item"
          onClick={() => props.history.push("/contact")}
        >
          CONTACT
        </span>
      </div>
      <div className="bottom-bar-right">
        <div className="bottom-bar-right-item search">
          <FontAwesome name="search" />
        </div>
        <div
          className="bottom-bar-right-item envelope-o"
          onClick={() => setContactVisible(!contactVisible)}
        >
          {contactVisible ? (
            <FontAwesome name="close" />
          ) : (
            <FontAwesome name="envelope-o" />
          )}
        </div>
        <div
          className="bottom-bar-right-item search"
          onClick={() => toggleSideBar()}
        >
          {sidebarVisible ? (
            <FontAwesome name="close" />
          ) : (
            <FontAwesome name="bars" />
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
