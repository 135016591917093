import "./index.scss";

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { isMobile } from "react-device-detect";

//Mobile View
import MLandingPage from "mobile/LandingPage";
import MAboutPage from "mobile/AboutPage";
import MProgramsPage from "mobile/ProgramsPage";
import MProjectsPage from "mobile/ProjectsPage";
import MOfficePage from "mobile/OfficePage";
import MPrinciplesPage from "mobile/PrinciplesPage";
import MContactPage from "mobile/ContactPage";

//Web View
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage";
import AboutPage from "views/AboutPage";
import ProgramsPage from "views/ProgramsPage";
import ProjectsPage from "views/ProjectsPage";
import OfficePage from "views/OfficePage";
import PrinciplesPage from "views/PrinciplesPage";
import ContactPage from "views/ContactPage";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/" exact component={isMobile ? MLandingPage : LandingPage} />
      <Route path="/about" component={isMobile ? MAboutPage : AboutPage} />
      <Route
        path="/programs"
        component={isMobile ? MProgramsPage : ProgramsPage}
      />
      <Route
        path="/projects"
        component={isMobile ? MProjectsPage : ProjectsPage}
      />
      <Route path="/office" component={isMobile ? MOfficePage : OfficePage} />
      <Route
        path="/principles"
        component={isMobile ? MPrinciplesPage : PrinciplesPage}
      />
      <Route
        path="/contact"
        component={isMobile ? MContactPage : ContactPage}
      />
      <Route path="*" component={Components} />
    </Switch>
    <ToastContainer />
  </Router>,
  document.getElementById("root")
);
