import "./index.scss";

import React, { Component } from "react";

import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

export class MapContainer extends Component {
  render() {
    return (
      <Map
        className="maps"
        // eslint-disable-next-line react/prop-types
        google={this.props.google}
        zoom={16}
        initialCenter={{ lat: -29.745275, lng: 30.9728973 }}
      >
        <Marker
          title={"P46 Bhejane Road"}
          position={{ lat: -29.745275, lng: 30.9728973 }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDzcde0xnDy9VOeiRL8uS5o2vTEX7is-is",
})(MapContainer);
