import "./index.scss";

import React from "react";

import NavBarFooter from "../NavBarFooter";
import ProjectCard from "./ProjectCard";
import projects from "./ProjectsData";

const Projects = (props) => (
  <NavBarFooter props={props}>
    <div className="mobile-projects-page animate__animated animate__fadeIn">
      <div className="mobile-top-section">
        <div className="title">Projects</div>
        <div className="sub-title">FMFA {"  >  "} Projects</div>
      </div>
      {projects.map((projects, index) => (
        <ProjectCard
          key={index}
          image={projects.image}
          title={projects.title}
          body={projects.body}
        />
      ))}
    </div>
  </NavBarFooter>
);

export default Projects;
