import React from "react";

import SlideShow from "./SlideShow";
import BottomSection from "./BottomSection";
import NavBarAndFooter from "components/NavBarAndFooter";

const LandingPage = (props) => (
  <NavBarAndFooter props={props}>
    <SlideShow />
    <BottomSection />
  </NavBarAndFooter>
);

export default LandingPage;
