import "./index.scss";

import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import GoogleMaps from "./google-maps.js";
import NavBarFooter from "../NavBarFooter";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

// eslint-disable-next-line react/prop-types
const CustomControlLabel = ({ icon, label }) => (
  <FormControlLabel
    className={"formControlLabel"}
    control={<Icon className={icon} color="black" style={{ color: "black" }} />}
    label={label}
  />
);

const OfficePage = (props) => {
  const classes = useStyles();

  return (
    <NavBarFooter props={props}>
      <div className="office-page animate__animated animate__fadeIn">
        <div className="mobile-top-section">
          <div className="title">Office</div>
          <div className="sub-title">FMFA {"  >  "} Office</div>
        </div>
        <div className="mobile-office-page-body">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3}>
              <CustomControlLabel
                icon={"fa fa-envelope"}
                label={"info@fmfa.co.za"}
              />

              <CustomControlLabel
                icon={"fa fa-phone"}
                label={
                  <span>
                    {"031-100-0141 "}
                    <br />
                    {"Cell& What’s app: +27 67 859 7724"}
                  </span>
                }
              />

              <CustomControlLabel
                icon={"fa fa-home"}
                label={"P46 Bhejane Road Units 22-23 KwaMashu Durban 4360"}
              />
            </Grid>
            <Grid item xs={12} sm={9} md={9}>
              <Paper className={classes.paper}>
                <GoogleMaps />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </NavBarFooter>
  );
};
export default OfficePage;
