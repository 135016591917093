import { toast } from "react-toastify";

export default (obj, fields) => {
  let result = true;

  fields.map((field) => {
    if (!obj[field] || obj[field] === "") {
      toast.error("Enter " + field);
      result = false;
      fields.length = 0;
    }
    return result;
  });

  return result;
};
