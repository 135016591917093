import "./slide-show.scss";

import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components

import banner1 from "assets/img/slide-show/banner1.jpg";
import banner2 from "assets/img/slide-show/banner2.jpg";
import banner3 from "assets/img/slide-show/banner3.jpg";

export default function Parallax() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <Carousel easing {...settings} className={"mobile-parallex  animate__animated animate__fadeIn"}>
      <div className="mobile-landing-page-slide-show">
        <img src={banner1} alt="First slide" className="mobile-slick-image" />
        <div className="mobile-slide-center-section">
          <h2 className="mobile-caption-title">Top Fashion Agency In Africa</h2>
          <h4 className="mobile-caption-center-text">
          </h4>
          <div
            className="read-more-button"
            onClick={() =>
              window.open(
                "https://web.facebook.com/fezilemdletshefashion/photos/a.986994041476430/1204730883036077",
                "_blank"
              )
            }
          >
            READ MORE
          </div>
        </div>
      </div>
      <div className="mobile-landing-page-slide-show">
        <img src={banner2} alt="Second slide" className="mobile-slick-image" />
        <div className="mobile-slide-center-section">
          <h2 className="mobile-caption-title">Harry Gwala Summer Cup</h2>
          <h4 className="mobile-caption-center-text">Project Managers</h4>
          <div
            className="read-more-button"
            onClick={() =>
              window.open(
                "https://web.facebook.com/fezilemdletshefashion/photos/a.986994041476430/1132453663597133",
                "_blank"
              )
            }
          >
            READ MORE
          </div>
        </div>
      </div>
      <div className="mobile-landing-page-slide-show">
        <img src={banner3} alt="Second slide" className="mobile-slick-image" />
        <div className="mobile-slide-center-section">
          <h2 className="mobile-caption-title">BET Made In Africa</h2>
          <h4 className="mobile-caption-center-text">Fashion Mentor & Judge</h4>
          <div
            className="read-more-button"
            onClick={() =>
              window.open(
                "https://web.facebook.com/fezilemdletshefashion/photos/a.986994041476430/1008257976016703",
                "_blank"
              )
            }
          >
            READ MORE
          </div>
        </div>
      </div>
    </Carousel>
  );
}
