/* eslint-disable react/prop-types */
import "./index.scss";

import React from "react";

const ProjectCard = ({ image, title, body }) => (
  <div className="mobile-project-card">
    <img src={image} alt="Project avatar" className="project-card-image" />
    <div className="mobile-project-card-body">
      <div className="mobile-project-card-title">{title}</div>
      <div className="project-card-paragraph">
        {body.split("-").map((sentence, index) => (
          <div key={index}>
            {`${sentence}`}
            <br />
            <br />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default ProjectCard;
