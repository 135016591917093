/* eslint-disable react/prop-types */
import "./index.scss";

import React from "react";

import NavBarAndFooter from "components/NavBarAndFooter";
import ServicesSlideShow from "./ServicesSildeShow";

const About = (props) => (
  <NavBarAndFooter props={props}>
    <div className="about-page animate__animated animate__fadeIn">
      <div className="top-section">
        <div className="title">About</div>
        <div className="sub-title">FMFA {"  >  "} About</div>
      </div>
      <div className="mission-section">
        <div className="mission-section-left">
          Fezile Mdletshe Fashion Agency is a Fashion Business Development
          Agency that primarily focuses on the advancement of the South African
          and African Fashion Industry.
        </div>
        <div className="mission-section-right">
          <div className="mission-section-right-title">ABOUT US</div>
          <div className="mission-section-right-body">
            The Fezile Mdletshe Fashion Agency was informally started in 2013
            through training cooperatives appointed by the Department of
            Economic Development, Tourism and Environmental Affairs & Social
            Development in Mandeni. It traded informally until 2017 when it
            became formally registered on the 3rd of October. Since then, the
            agency has been in operation for 3 years and eventually founded the
            Fezile Fashion Skills Academy in 2018 December 12.
            <br />
            <br />
            The Fashion Agency has done various projects including 1. Dundee
            July Fashion Mentorship Programme 2014-2018 2. Harry Gwala Summer
            Cup Fashion Mentorship Programme 2018 3. KZNFC 21 Steps to Retail
            Fashion Mentorship Programme 2014-2018 4. KZNFC SA FASHION WEEK
            Fashion Mentorship Programme 2016 5. KZNFC & The Space Retail Stores
            Fashion Mentorship Programme 2016-2017 6. Drakensberg Extravaganza
            Fashion Mentorship Programme & Fashion Production 2018 7. Lagos
            Fashion Week Project Manager-South Africa 2018 8. SEDA Fashion
            Mentorship Programme uMtata-2019 and now currently doing the Durban
            Fashion Fair Mentorship Programme 2020.
            <br />
            <br />
            The core of FMFA is to provide a creative development platform which
            operates as a fashion business incubator for emerging fashion
            designers. The Agency & the Academy are in constant collaboration
            whereby the Fezile Fashion Skills Academy trains designers on the
            technical part of clothing & fashion design, the Agency’s focus is
            entrepreneurship, business and retail.
            <br />
            <br />
            The Fezile Mdletshe Fashion Agency will be launching a new project
            INK FASHION WEEK as a method of market access for designers. The
            fashion week will focus on further developing designers with its
            primary focus being on the on the advancement of the South African
            and African Fashion Industry.
          </div>
        </div>
      </div>
      <div className="our-services-section">
        <div className="our-services-section-slide-show">
          <ServicesSlideShow />
        </div>
        <div className="our-services-section-content">
          <div className="our-services-section-content-title">OUR SERVICES</div>
          <div className="our-services-section-content-body">
            We offer a range of services that can be tailor made to suit your
            goals and objectives:
            <br />
            <br />
            • Creative Direction
            <br />
            • Fashion Production
            <br />
            • Market and Brand
            <br />
            Positioning
            <br />
            • Retail Strategy
            <br />
            • Mentoring Program
            <br />• Market Analysis
          </div>
        </div>
      </div>
    </div>
  </NavBarAndFooter>
);

export default About;
