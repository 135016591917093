import "./index.scss";

import React, { useState } from "react";

import NavBarAndFooter from "components/NavBarAndFooter";
import Socials from "components/Socials";
import appsettings from "appsettings.json";
import * as api from "api/emailApi.js";
import validation from "shared/utils/validations";

const ContactPage = (props) => {
  const [email, setEmail] = useState({});

  const onChangeText = (event, key) => {
    event.persist();
    setEmail({
      ...email,
      [key]: event.target.value,
    });
  };

  return (
    <NavBarAndFooter props={props}>
      <div className="contact-page animate__animated animate__fadeIn">
        <div className="top-section">
          <div className="title">Contact</div>
          <div className="sub-title">FMFA {"  >  "} Contact</div>
        </div>
        <div className="contact-page-body">
          <div className="say-hello-section">
            <div className="contact-page-body-title">SAY HELLO</div>
            <br />
            <br />
            <br />
            <input
              className="contact-page-body-input"
              type="text"
              name="your-name"
              value={email.name}
              size="40"
              aria-required="true"
              aria-invalid="false"
              placeholder="Your Name *"
              onChange={(event) => onChangeText(event, "name")}
            />
            <input
              className="contact-page-body-input"
              type="text"
              name="your-email"
              value={email.email}
              size="40"
              aria-required="true"
              aria-invalid="false"
              placeholder="Your Email *"
              onChange={(event) => onChangeText(event, "email")}
            />
            <input
              className="contact-page-body-input"
              type="text"
              name="subject"
              value={email.subject}
              size="40"
              aria-required="true"
              aria-invalid="false"
              placeholder="Subject *"
              onChange={(event) => onChangeText(event, "subject")}
            />
            <textarea
              className="contact-page-body-textarea"
              name="your-message"
              value={email.message}
              cols="40"
              rows="15"
              aria-invalid="false"
              placeholder="Your message"
              onChange={(event) => onChangeText(event, "message")}
            />
            <div
              className="contact-page-body-send-button"
              onClick={() => {
                if (
                  validation(email, ["name", "email", "subject", "message"])
                ) {
                  api.sendMail({
                    to: appsettings[appsettings.environment].sendToEmail,
                    message: {
                      subject: "Name: " + email.name + " Email: " + email.email,
                      text: email.message,
                    },
                  });
                  setEmail({ name: "", email: "", subject: "", message: "" });
                }
              }}
            >
              SEND
            </div>
          </div>
          <div className="careers-section">
            <div className="contact-page-body-title">CAREERS</div>
            <br />
            <br />
            <br />
            <div className="contact-page-body-careers">
              There are presently no advertised vacancies at this time. However,
              if you feel you could add value to our team, please feel free to
              send your resume to us at <b>info@fmfa.co.za</b>.
            </div>
            <br />
            <br />
            <div className="contact-page-body-title">SOCIAL</div>
            <br />
            <br />
            <br />
            <Socials />
          </div>
          <div className="email-section">
            <div className="contact-page-body-title">EMAIL</div>
            <br />
            <br />
            <br />
            <div className="contact-page-body-careers">
              Email: info@fmfa.co.za
            </div>
          </div>
        </div>
      </div>
    </NavBarAndFooter>
  );
};
export default ContactPage;
