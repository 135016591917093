/* eslint-disable react/prop-types */
import "./index.scss";

import React from "react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const ScrollUpButton = ({ scrollUpButtonVisible }) => (
  <div
    className={`scroll-up-button ${scrollUpButtonVisible ? "show" : "hide"}`}
    onClick={() => window.scrollTo(0, 0)}
  >
    <ExpandLessIcon fontSize={"large"} style={{ color: "white" }} />
  </div>
);

export default ScrollUpButton;
