/* eslint-disable react/prop-types */
import "./index.scss";

import React from "react";

const SideNavBar = ({ isVisible, props }) => {
  return (
    <div
      className={`side-nav-bar  ${
        isVisible ? "side-nav-bar-open" : "side-nav-bar-close"
      }`}
    >
      <div className="side-nav-bar-items">
        <div
          className="side-nav-bar-item"
          onClick={() => props.history.push("/about")}
        >
          ABOUT
        </div>
        <div
          className="side-nav-bar-item"
          onClick={() => props.history.push("/programs")}
        >
          PROGRAMS
        </div>
        <div
          className="side-nav-bar-item"
          onClick={() => props.history.push("/projects")}
        >
          PROJECTS
        </div>
        <div
          className="side-nav-bar-item"
          onClick={() => props.history.push("/")}
        >
          HOME
        </div>
        <div
          className="side-nav-bar-item"
          onClick={() => props.history.push("/office")}
        >
          OFFICE
        </div>
        <div
          className="side-nav-bar-item"
          onClick={() => props.history.push("/principles")}
        >
          PRINCIPLES
        </div>
        <div
          className="side-nav-bar-item"
          onClick={() => props.history.push("/contact")}
        >
          CONTACT
        </div>
      </div>
    </div>
  );
};

export default SideNavBar;
