import "./index.css";

import React from "react";
import FontAwesome from "react-fontawesome";

const BottomSection = () => (
  <div className="bottom-section-footer">
    <div className="bottom-section-footer-top">
      <FontAwesome
        className="bottom-bar-left-item"
        name="twitter"
        onClick={() =>
          window.open("https://twitter.com/fmfa_official", "_blank")
        }
      />
      <FontAwesome
        className="bottom-bar-left-item"
        name="facebook"
        onClick={() =>
          window.open(
            "https://web.facebook.com/fezilemdletshefashion",
            "_blank"
          )
        }
      />
      <FontAwesome
        className="bottom-bar-left-item"
        name="linkedin"
        onClick={() =>
          window.open(
            "https://za.linkedin.com/in/fezile-l-mdletshe-9b66405b",
            "_blank"
          )
        }
      />
      <FontAwesome
        className="bottom-bar-left-item"
        name="instagram"
        onClick={() =>
          window.open(
            "https://www.instagram.com/fezile_mdletshe_fashion_agency/",
            "_blank"
          )
        }
      />
      <FontAwesome
        className="bottom-bar-left-item"
        name="whatsapp"
        onClick={() => window.open("https://wa.me/27678597724", "_blank")}
      />
    </div>
    <div className="bottom-section-footer-bottom">
      <div>©2020 Fezile Mdletshe Fashion Agency. All Rights Reserved.</div>
      <div>Assembled by Target Online Pty Ltd.</div>
    </div>
  </div>
);

export default BottomSection;
