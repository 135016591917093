import firebase from "firebase";
import { toast } from "react-toastify";
import moment from "moment";

import appsettings from "appsettings.json";

if (!firebase.apps.length)
  firebase.initializeApp(appsettings[appsettings.environment].firebaseConfig);

const db = firebase.firestore();

export const sendMail = async (email) => {
  await login();
  db.collection("mail")
    .doc(moment(new Date()).format("YYYY MMM DD HH:mm:ss.SSSSSS"))
    .set(email)
    .then(() => toast.success("Email sent."))
    .catch((error) => toast.error(error.message));
};

export const login = () =>
  db.app
    .auth()
    .signInWithEmailAndPassword(
      appsettings.serviceAccount,
      appsettings[appsettings.environment].firebaseConfig.apiKey
    )
    .then(
      () => {},
      (error) => toast.error(error.message)
    );
