/* eslint-disable react/prop-types */
import "./index.scss";

import React from "react";
import FontAwesome from "react-fontawesome";

const Menu = ({ props, setVisible }) => {

    return (
    <div className="mobile-navbar-menu">
        <FontAwesome size="2x" className="close-menu" name="close" onClick={() => setVisible(false)} />
        <div className="mobile-navbar-menu-items  animate__animated animate__zoomIn">
            <div className="mobile-navbar-menu-item" onClick={() => props.history.push("/")}> Home </div>
            <div className="mobile-navbar-menu-item" onClick={() => props.history.push("/about")}> About </div>
            <div className="mobile-navbar-menu-item" onClick={() => props.history.push("/programs")}> Programs </div>
            <div className="mobile-navbar-menu-item" onClick={() => props.history.push("/projects")}> Projects </div>
            <div className="mobile-navbar-menu-item" onClick={() => props.history.push("/office")}> Office </div>
            <div className="mobile-navbar-menu-item" onClick={() => props.history.push("/principles")}> Principles </div>
            <div className="mobile-navbar-menu-item" onClick={() => props.history.push("/contact")}> Contact </div>
        </div>
    </div>
)
    }
export default Menu;