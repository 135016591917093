/* eslint-disable react/prop-types */
import "./index.scss";

import React, { useState } from "react";
import FontAwesome from "react-fontawesome";

import Menu from "./Menu"

const NavBar = ({ props }) => {
    const [isMenuVisible, setMenuVisible] = useState(false);

    return (
    <div className="mobile-navbar">
        {isMenuVisible && <Menu setVisible={setMenuVisible} props={props}/>}
        <FontAwesome size="2x" name="bars" onClick={() => setMenuVisible(true)}/>
        <img className="mobile-navbar-logo" src={require("assets/img/icon.jpg")} onClick={() => props.history.push("/")}/>
        <div />
    </div>
    )
}

export default NavBar;