export default [
  {
    title: "The Iconic Durban Fashion Fair (DFF)",
    image: require("assets/img/projects/durban-fashion-fair.jpg"),
    body: `The iconic Durban Fashion Fair, brought to you by the eThekwini Municipality, now in its 9th year (2020), the
     DFF is a fashion extravaganza boasting an array of international, national and emerging designers showcasing their
     creations on the catwalk. It is certainly the much anticipated highlight of Durban's annual fashion calenda.`,
  },
  {
    title: "Lagos Fahsion Week Project Managers",
    image: require("assets/img/projects/lagos-fashion-week.jpg"),
    body: `Identify young emerging South African designers for Lagos Fashion Week 2018 market access programme, as a method of potentially exposing and promoting designers on international stage. 
      - Provide opportunities for designers to extend their market for products in terms of export
      - Provide the opportunity for design is to meet the fabric Mills with the aim to either manufacture or import fabrics or parts of Accessories of South African in order to build their businesses.
      - Form collaborations and build relations with international designers and markets which supports SA designers in promoting the profile of their brand.
      - Increase in the diversification of local fashion industry creating an environment of fashion exports competitiveness.
      - Establishing a culture of networking amongst young, emerging and established designers locally and internationally.
      `,
  },
  {
    title:
      "Drakensberg Extravaganza 2018 Fashion Showcase & Development Programme",
    image: require("assets/img/projects/drankensbaerg-extravaganza.jpg"),
    body: ` Training, Event Coordination and Management.
        - Organizing the fashion show.
        - Conceptualizing themes and music.
        - Determining the fashion show theme and name.
        - Selecting and putting together a committee to assist execute the show.
        - Finalizing programme.
        - Organizing catering.
        - Calling.
        - Programme and creative directing.
        - Setting up.
        - Planning sequence.
        - Model Casting.
        - Designing of poster and graphics.
      `,
  },
  {
    title: "Mentor-21 Steps To Retail Fashion Programme",
    image: require("assets/img/projects/mentor-21-steps-to-retail-fashion-program.jpg"),
    body: `FMFA have been part of three 21 Steps to Retail programmes developing and mentoring designers to business readiness and access to markets.  This programme has seen the success of over 100 designers taking them from basic and intermediate fashion knowledge to South African Fashion Week, Pure London and Africa Fashion Exchange Runways.
    - Presenting on seasonal fashion trends in relation to season and fashion show themes.
    - Interrogating the design process from inspiration to collection.
    - Coaching designers in conceptualizing seasonal themes into runway and retail ready collection.
    - Business readiness- preparing designers for retail opportunities.
    - Coaching designers in business administration skills such budgets, costing, pricing, marketing, report writing and professional etiquette.
    `,
  },
  {
    title: "Mentor-The Space Designers Business Incubation Project",
    image: require("assets/img/projects/mentor-the-space-designers-and-incubation-project.jpg"),
    body: ` Business readiness- preparing designers for retail opportunities.
    - Grooming designers to be knowledgeable business entities that possess key clothing and textiles industry knowledge, costing, marketing, fashion trend, consumer demographic analysis skills, with a good grasp in understanding production critical path.
    - Understanding of the clothing and textiles leather international and local manufacturing sector, the policies and frameworks.
    - Developing strategic development plans for designers under the iGoda business incubation.
    - Developing entrepreneurial developmental strategies aligned with that of iGoda Incubator and KwaZulu-Natal Fashion Council.
    - Retail and production management.
    - Development of critical path which is currently assisting and guiding The Space designers under incubation on; production timelines, clothing, lead time and retail management.
    - Mentoring and polishing the skills of emerging designers in terms of, business acumen, range development, fashion trends, market segmentation, and location and pricing.
    - Planning of adequate monthly store units and drops.
    - Advising on suitable market related costing of items.
    - Approval of fabrics/textiles and designs for seasonal collections.
    - Planning seasonal collections that are retail suitable.
    - Developing and designing of processes that are aligned with the Clothing and Textiles Industry such as the Critical Path, Maps and Items sheets.
    - Giving designers season creative direction and range building.
    - Price positioning in relation to market and competitors.
    - Store visits and competitor analysis.
    - Monthly report writing and proposal presentations.
    - Checking of store returns and quality.
    `,
  }
];