/* eslint-disable react/prop-types */
import "./index.scss";

import React from "react";
import Socails from "components/Socials";

const Footer = (props) => (
    <div className="mobile-footer">
        <Socails />
        <div className="mobile-footer-bottom">
            <div>©2020 Fezile Mdletshe Fashion Agency. All Rights Reserved.</div>
            <div>Assembled by Target Online Pty Ltd.</div>
        </div>
    </div>
)
export default Footer;