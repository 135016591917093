/* eslint-disable react/prop-types */

import React from "react";

import NavBar from "./NavBar";
import Footer from "./Footer";

const NavBarFooter = ({ props, children }) => (
  <React.Fragment>
    <NavBar props={props} />
      {children}
    <Footer />
  </React.Fragment>
)
export default NavBarFooter;