export default [
  {
    title: "The Iconic Durban Fashion Fair (DFF)",
    image: require("assets/img/programs/durban-fashion-fair.jpg"),
    body: `The iconic Durban Fashion Fair, brought to you by the eThekwini Municipality, now in its 9th year (2020), the
     DFF is a fashion extravaganza boasting an array of international, national and emerging designers showcasing their
     creations on the catwalk. It is certainly the much anticipated highlight of Durban's annual fashion calenda.`,
  },
  {
    title: "ANDA",
    image: require("assets/img/programs/anda.jpg"),
    body: `Fezile Fashion Skills Academy (Pty) Ltd is conducting a    Clothing Manufacturing Level 2  programme  which has enrolled 50 students. Students have been assessed and  grouped according to their strengths are weaknesses into two groups. The first two months students at each level will participate in the teaching and learning of all modules under both qualifications. As the months progress into integrated projects. The integration will assist in achieving a seamless understanding in how the 3 main pillars of fashion operate, these being: design, pattern and garment technology. The introduction of these modules will hopefully contribute to the development of designers, pattern makers and garment technologists into fashion entrepreneurs who can begin to play a small role in contributing to the fashion value chain and economy through owning small businesses, employing others and hopefully begin to alleviate poverty. This initiative is aimed at complementing ANDA’s economic development plans.`,
  },
  {
    title:
      "Drakensberg Extravaganza 2018 Fashion Showcase & Development Programme",
    image: require("assets/img/programs/drankensbaerg-extravaganza.jpg"),
    body: ` Training, Event Coordination and Management.
        - Organizing the fashion show.
        - Conceptualizing themes and music.
        - Determining the fashion show theme and name.
        - Selecting and putting together a committee to assist execute the show.
        - Finalizing programme.
        - Organizing catering.
        - Calling.
        - Programme and creative directing.
        - Setting up.
        - Planning sequence.
        - Model Casting.
        - Designing of poster and graphics.
      `,
  },
  {
    title: "Mentor-21 Steps To Retail Fashion Programme",
    image: require("assets/img/programs/mentor-21-steps-to-retail-fashion-program.jpg"),
    body: `FMFA have been part of three 21 Steps to Retail programmes developing and mentoring designers to business readiness and access to markets.  This programme has seen the success of over 100 designers taking them from basic and intermediate fashion knowledge to South African Fashion Week, Pure London and Africa Fashion Exchange Runways.
    - Presenting on seasonal fashion trends in relation to season and fashion show themes.
    - Interrogating the design process from inspiration to collection.
    - Coaching designers in conceptualizing seasonal themes into runway and retail ready collection.
    - Business readiness- preparing designers for retail opportunities.
    - Coaching designers in business administration skills such budgets, costing, pricing, marketing, report writing and professional etiquette.
    `,
  },
  {
    title: "Mentor & Fashion Director- KZNFC Dundee July Fashion Programme",
    image: require("assets/img/programs/kznfc-dundee-fashion-july-prgramme.jpg"),
    body: ` The Dundee July is an annual event initiated by the Department of Sports and Recreation, and Department of Arts and Culture. Since 2014 I have been strategic partner with KZNFC as a Mentor facilitating and ensuring the implementation of training workshops leading up to the Race Day event. Being a Mentor for the Dundee July has been a very fulfilling experience as I have seen tremendous growth in designers such as Rachel Molefe of the Motherboard Project and Musawenkosi Sebeko of Afro Amanno. The mentorship guidance offered by myself alongside this KZN Fashion Council initiative has given designers a platform to grow and build on their brands, by consistently showcasing full collections at the Race Day event. This has created market access which harvests opportunities of supplying local retail spaces and international tradeshows
    - In four years I have seen four Dundee July Fashion programmes to fruition mentoring over 200 designers. I have also availed myself privately and continued mentorship sessions with design as a method of encouraging a consistent work ethic. This kind of support has allowed designers to flourish even outside of set Race Day workshops.
    `,
  },
  {
    title:
      "Mentor- South African Fashion Week 2015 Designers Mentorship Workshop",
    image: require("assets/img/programs/mentor-south-africa-dashion-week-2015.jpg"),
    body: `In 2015 the KZNFC trusted me with the responsibility to coach and ensure a smooth transition of designers moving from various fashion programmes into SA Fashion Week. Designers had to be trained, groomed and prepared for runway and retail (Buyers lounge).
    - I had to coach selected designers such as Mapitso, Duke Mngadi, Nicole Hoyer and Vanessa of Hombre on the following aspects
    - Presentation skills 
    - Communication skills to potential buyers.
    - Portfolio presentation and layout.
    - Mood board presentation and technical drawings.
    - Range building
    - Pricing in relation to cost, fabrication, market segmentation and marketing.
    - Price grouping by product categories in relation to market and economy.
    - Identification of competitors and analysis.
    - Market analysis in relation to the economy.
    - Business etiquette in relation to presentations, proposals, email communication and communication in general.
    `,
  },
  {
    title: "Applied Design, Textiles & Materials Facilitator & Mentor)",
    image: require("assets/img/programs/applied-design-textiles-and-materials.jpg"),
    body: ` Creative Direction for Clothing and Textiles.
    - Analysis of the past future impacts and implications of the clothing industry.
    - Educating on the South African clothing, manufacturing, textile and leather industry, regulations, policies, frameworks and economic factors.
    - Educating on textiles and textile sector implications on economic factors and, various materials sector.
    - Educating on CPA (consumer protection act) policies, clothing quality assurance, SARs customs external policy and labeling requirements.
    - Educating on the clothing and textiles policies of the Department of Trade and Industry and SABS.
    - Development and design of annual curriculum plan in relation to the SAQA requirements and FP and M Seta stipulations. 
    - Reporting and consolidating on post mortem annual curriculum activities.
    - Developing sustainable programmes to foster an entrepreneurial nature amongst novice designers.
    - Teaching and developing novice designers into full-fledged fashion designers and life long learners.
    - Educating on current fashion trends using key and global trend sites such as WGSN.
    - Preparing learning materials and notes for students.
    - Conducting assessments related to Applied Design and Materials.
    - Designing sustainable programmes that will accelerate the learning of iGoda students into fashion entrepreneurs.
    - Weekly Report writing.
    - Preparing designer for business incubation and retail avenues.
    `,
  }
];