/* eslint-disable react/prop-types */
import "./index.scss";

import React from "react";

import NavBarAndFooter from "components/NavBarAndFooter";

const Values = (props) => (
  <NavBarAndFooter props={props}>
    <div className="principles-page animate__animated animate__fadeIn">
      <div className="top-section">
        <div className="title">Principles</div>
        <div className="sub-title">FMFA {"  >  "} Principles management</div>
      </div>
      <div className="principles-section">
        <div className="principles-section-left">
          Fezile Mdletshe Fashion Agency is a Fashion Business Development
          Agency that primarily focuses on the advancement of the South African
          and African Fashion Industry.
        </div>
        <div className="principles-section-right">
          <div className="principles-section-right-title">MISSION</div>
          <div className="principles-section-right-body">
            To educate by developing capacity building programmes that foster
            sustainable fashion business concepts.
          </div>
          <br />
          <br />
          <div className="principles-section-right-title">VISION</div>
          <div className="principles-section-right-body">
            The Fezile Mdletshe Fashion Agency is a socially responsible
            initiative that seeks to promote fashion within previously
            disadvantage areas.
          </div>
          <br />
          <br />
          <div className="principles-section-right-title">VALUES</div>
          <div className="principles-section-right-body">
            We spare no effort in:
            <br />
            <br />
            Creating a collaborative training spaces that support and meet the
            needs of the South African fashion industry.
            <br />
            <br />
            Training by nurtuting black aspiring designers through the FMFA
            framework with an aim to enhance talent, cultivate skill set,
            abilities and competencies through use of modern skills development
            (training, coaching and mentoring) methods.
            <br />
            <br />
            Participation in external industry engagement that promotes
            innovation and sustainable fashion entrepreneurship through
            collaboration.
            <br />
            <br />Mentoring designers to be creative agents that evolve to be
            influencers of their Provincial Clothing, Textile and manufacturing
            sector and South African fashion industry.
          </div>
        </div>
      </div>
    </div>
  </NavBarAndFooter>
);

export default Values;
