import React from "react";
import Carousel from "react-slick";

import image1 from "assets/img/our-services-slide-show/1.jpg";
import image2 from "assets/img/our-services-slide-show/2.jpg";
import image3 from "assets/img/our-services-slide-show/3.jpg";
import image4 from "assets/img/our-services-slide-show/4.jpg";

export default function ServicesSlideShow() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <Carousel {...settings}>
      <img src={image1} alt="First slide" className="mobile-about-slick-image" />
      <img src={image2} alt="Second slide" className="mobile-about-slick-image" />
      <img src={image3} alt="Third slide" className="mobile-about-slick-image" />
      <img src={image4} alt="Fourth slide" className="mobile-about-slick-image" />
    </Carousel>
  );
}
