import "./index.scss";

import React from "react";

import NavBarAndFooter from "components/NavBarAndFooter";
import ProjectCard from "./ProjectCard";
import projects from "./ProjectsData";

const Programs = (props) => (
  <NavBarAndFooter props={props}>
    <div className="projects-page animate__animated animate__fadeIn">
      <div className="top-section">
        <div className="title">Projects</div>
        <div className="sub-title">FMFA {"  >  "} Projects</div>
      </div>
      {projects.map((projects, index) => (
        <ProjectCard
          key={index}
          image={projects.image}
          title={projects.title}
          body={projects.body}
        />
      ))}
    </div>
  </NavBarAndFooter>
);

export default Programs;
