import "./top-bar.css";

import React from "react";

const NavBar = () => {
  return (
    <div className="top-bar">
      <div className="col-sm-6 tb-left">
        <div className="tb-text">
          <strong>FMFA</strong>
        </div>
      </div>
      <div className="col-sm-6 tb-right">
        <div className="tb-text">
          <i>Fezile Mdletshe Fashion Agency</i>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
