/* eslint-disable react/prop-types */
import "./index.css";

import React from "react";

import TopBar from "./top-bar";
import BottomBar from "./bottom-bar";

const NavBar = ({
  props,
  scrollY,
  contactVisible,
  sidebarVisible,
  setSideBarHidden,
  setSideBarVisible,
  setContactVisible,
}) => {
  return (
    <div className="navbar">
      <TopBar />
      <BottomBar
        scrollY={scrollY}
        props={props}
        contactVisible={contactVisible}
        sidebarVisible={sidebarVisible}
        setSideBarVisible={setSideBarVisible}
        setSideBarHidden={setSideBarHidden}
        setContactVisible={setContactVisible}
      />
    </div>
  );
};

export default NavBar;
