/* eslint-disable react/prop-types */
import "./index.scss";

import React from "react";

import NavBarFooter from "../NavBarFooter";
import SlideShow from "./SlideShow"

const LandingPage = (props) => (
    <NavBarFooter props={props}>
        <SlideShow />
    </NavBarFooter>
)

export default LandingPage;