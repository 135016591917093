/* eslint-disable react/prop-types */
import "./index.scss";
import React, { useState } from "react";

import NavBar from "./NavBar";
import Footer from "./Footer";
import ScrollUpButton from "./ScrollUpButton";
import SideNavBar from "./SideNavBar";
import ContactPage from "views/ContactPage/ContactPage";

const NavBarAndFooter = ({ props, children }) => {
  const [scrollY, setScrollY] = useState(0);
  const [scrollUpButtonVisible, setScrollUpButtonVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);
  const [sidebarVisible, setSideBarVisible] = useState(false);
  const [sidebarHidden, setSideBarHidden] = useState(false);

  window.onscroll = function () {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setScrollUpButtonVisible(true);
    }
    if (window.innerHeight + window.scrollY < document.body.offsetHeight) {
      setScrollUpButtonVisible(false);
    }

    setScrollY(window.scrollY);
  };

  return (
    <React.Fragment>
      <SideNavBar isVisible={sidebarVisible} props={props} />
      <div
        className={`
        ${
          sidebarVisible
            ? "move-navbar-left"
            : sidebarHidden
            ? "move-navbar-back"
            : ""
        }`}
      >
        <NavBar
          scrollY={scrollY}
          props={props}
          contactVisible={contactVisible}
          sidebarVisible={sidebarVisible}
          setSideBarHidden={setSideBarHidden}
          setSideBarVisible={setSideBarVisible}
          setContactVisible={setContactVisible}
        />
        {contactVisible && <ContactPage />}
        {!contactVisible && children}
        <Footer />
      </div>
      <ScrollUpButton scrollUpButtonVisible={scrollUpButtonVisible} />
    </React.Fragment>
  );
};

export default NavBarAndFooter;
