import "./index.css";

import React from "react";

const BottomSection = () => (
  <div className="bottom-section-top">
    <div className="bottom-section-top-left"></div>
    <div className="bottom-section-top-center">
      Driven by fashion. Sustained by passion.
    </div>
    <div className="bottom-section-top-right"></div>
  </div>
);

export default BottomSection;
